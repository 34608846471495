<template>
  <v-container>
    <v-row class="" align="center" justify="center">
      <v-col v-if="saleStart > 0 && !showPresale">
        <v-row class="text-h4 text-center" align="center" justify="center">
          <span class="sequel-85"> PUBLIC MINT OPENS IN: </span>
        </v-row>
        <v-row class="text-h4 text-center white--text" align="center" justify="center">
          <countdown
            class="white--text sequel-85"
            @finish="onPresaleUp"
            :timeTarget="saleStart"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-row class="" align="center" justify="center">
      <v-col cols="12" v-if="showPresale">
        <v-row class="text-p text-center" align="center" justify="center">
          Max. 5 per wallet
        </v-row>
        <v-row class="text-p text-center" align="center" justify="center">
          {{ salePrice }} Ξ per NFT
        </v-row>
        <v-row class="mt-10" align="center" justify="center">
          <sub-button />
          <number-to-mint />

          <add-button />
        </v-row>
        <v-row class="mt-10" align="center" justify="center">
          <mint-button
            titleButton="MINT NOW"
            variation="saleMint"
            :highlighted="true"
            class="mr-1 mr-sm-3"
          />

          <mint-button
            titleButton="MINT MAX"
            variation="saleMint"
            :number="5"
            :highlighted="true"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddButton from "./AddButton.vue";
import Countdown from "./Countdown.vue";
import MintButton from "./MintButton.vue";
import NumberToMint from "./NumberToMint.vue";
import SubButton from "./SubButton.vue";
export default {
  components: { Countdown, MintButton, SubButton, AddButton, NumberToMint },
  data() {
    return {
      showPresale: false,
    };
  },
  methods: {
    onPresaleUp() {
      this.showPresale = true;
    },
  },
  computed: {
    saleStart() {
      return this.$store.state.contract.saleStart;
    },
    salePrice() {
      return this.$store.state.contract.salePrice / 1000000000000000000;
    },
  },
};
</script>

<style>
</style>