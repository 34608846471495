<template>
  <v-main style="padding-top: 20vh">
    <v-container>
      <v-row align="center" justify="center">
        <v-col>
          <v-row justify="center">
            <h1 class="sequel-85">
              ABOUT <br />
              CANDY ROBBERS
            </h1>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" lg="8" xl="6" class="text-center">
              <h4 class="sequel-45 font-weight-thin">
                Be aware that you may be exposed to totally uncommon, unexpected
                and surprising scenes. In fact, for several weeks now, a gang of
                robbers has been attacking in several cities in the world, the
                most precious candy stores.
              </h4>
            </v-col>
          </v-row>
          <v-row justify="center" class="mb-5">
            <img style="max-height: 340px" src="./../assets/Chocolate.gif" />
          </v-row>
          <v-row justify="center">
            <base-button
            @click="goToAnchor('mint')"
              ><a style="color: black">GET YOUR CANDY ROBBER</a></base-button
            >
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <img
      src="@/assets/banner-about.png"
      style="
        margin-top: 50px;
        margin-bottom: 50px;
        min-width: 100vw;
        max-width: 100vw;
      "
      
    />

    <v-row justify="center" align="center" class="px-lg-16 mx-2">
      <v-col lg="4" cols="12">
        <v-row justify="center" class="">
          <img
            style="max-height: 440px; max-width: 80%"
            src="./../assets/police-officer.gif"
          />
        </v-row>
      </v-col>
      <v-col cols="12" lg="4" class="text-center">
        <v-row align="center" justify="center" class="" id="mint">
          <h2 class="sequel-85">MINT IS LIVE</h2>
        </v-row>
        <v-row justify="center" class="mb-10">

          <h3 class="sequel-45 font-weight-thin">First come, first served</h3>
        </v-row>
        <v-row v-if="!connected" justify="center">
          <connect-button />
        </v-row>
        <v-row v-else>
          <v-container v-if="whitelisted">
            <mint-presale />
          </v-container>
          <v-container v-else>
            <mint-public-add-sub/>
          </v-container>
        </v-row>
      </v-col>
      <v-col cols="4" class="d-none d-lg-block">
        <v-row justify="center" class="">
          <img style="max-height: 440px; max-width: 80%" src="./../assets/Chocolate.gif" />
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="mx-2">
      <v-col cols="10" sm="8" lg="6">
        <feedback-display/>
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      class="mx-2 mx-sm-10 px-sm-10 mx-lg-16 px-lg-16"
    >
      <v-container
        style="
          background-color: white;
          border-radius: 12px;
          border: 1px solid #210912;
        "
        class="px-7 px-sm-16"
      >
        <v-row justify="center" class="mt-sm-13">
          <v-col cols="12" sm="6" class="">
            <v-row class="text-h6 text-sm-h5 mb-5 mt-3">
              <h2 class="sequel-85">
                ROBBERIES <br />
                EVENTS
              </h2>
            </v-row>
            <v-row class="sequel-45 mb-16">
              <p>
                Each month, Candy Robbers will gather for robberies events and
                attack the most precious candy shops in the world.
              </p>
              <p>
                Candy Robbers owners will decide to participate to events. After
                successful robberies, your Candy Robber might come home with a
                new lolipop, a rare ethereum gummy or a new background he stole
                during the heist.
              </p>
              <p>
                Watch out, if Candy Robbers get caught by police officers they
                might get some traits confiscated and end up losing assets. Will
                you risk it all for a rewarding loot?
              </p>
            </v-row>
            <v-row class="mb-16">
              <base-button
              @click="rob"
                >ROB CANDY SHOP</base-button
              >
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="mb-10">
            <v-row justify="center" align="center">
              <img style="max-width: 90%; border: 1px solid #210912;" :src="robber" alt="" />
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row
      justify="center"
      class="mx-2 mx-sm-10 px-sm-10 mx-lg-16 px-lg-16 mt-16"
    >
      <v-container class="pa-0 carousel-container" style="overflow: hidden;">
        <v-row
          justify="start"
          justify-sm="center"
          class="text-h6 text-sm-h5 mb-4 mb-sm-10 mt-lg-10 pa-3 ml-2 mt-2"
        >
          <h2 class="sequel-85">NO PROMISE, NO BS, ROBBERIES ONLY</h2>
        </v-row>
        <v-row
          justify="start"
          justify-sm="center"
          class="text-h6 text-sm-h5 mb-lg-3 my-5 pa-3 ml-2"
        >
          <h4 class="sequel-45">
            Candy Map listing shops for next robbery targets.
          </h4>
        </v-row>
        <v-row justify="center">
          <v-carousel
            :show-arrows="false"
            hide-delimiter-background
            delimiter-icon="mdi-checkbox-blank-circle-outline"
            :height="carouselHeight"
            class=""
          >
            <v-carousel-item>
              <v-row justify="center">
                <img
                  src="@/assets/candy-map.png"
                  style="max-width: 100%"
                  alt=""
                />
              </v-row>
            </v-carousel-item>
            <v-carousel-item>
              <v-row justify="center" class="pa-0" align="end">
                <img
                  src="@/assets/candy-island.png"
                  style="width: 100%; height: auto; position: absolute; bottom: 0px;"
                  alt=""
                  class="my-3"
                />
              </v-row>
            </v-carousel-item>
          </v-carousel>
        </v-row>
      </v-container>
    </v-row>
    <v-row
      align="center"
      justify="center"
      class="mx-2 mx-sm-10 px-sm-10 mx-lg-16 px-lg-16 mt-16 mb-16"
    >
      <v-container
        style="
          background-color: white;
          border-radius: 12px;
          border: 1px solid #210912;
        "
        class="px-7 px-sm-16"
      >
        <v-row justify="center" class="mt-sm-13">
          <v-col cols="12" sm="6" class="">
            <v-row class="text-h6 text-sm-h5 mb-5 mt-3">
              <h2 class="sequel-85">THE ARTIST</h2>
            </v-row>
            <v-row class="sequel-45 mb-16">
              <p>
                The Art of Candy Robbers brings back childhood memories and
                candies features. Meet our colorfuls traits, meaningful
                references and playful style. The collection is algorithmically
                generated from +150 unique hand-drawn traits illustrated by Yuri
                and animated by Alessio.
              </p>
              <p>
                We aim to create a powerful animated collection for NFTs and Web
                3 enthusiasts. We want to gather during on-chain events
                (robberies) and develop a long-term community of candy lovers!
              </p>
              <p>
                Animating all Candy Robbers really makes them alive and match
                our vision with digital + physical events where we could all
                meet & have fun.
              </p>
            </v-row>
            <v-row class="mb-16">
              <base-button
              @click="goToAnchor('mint')"
                >JOIN US</base-button
              >
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="mb-10">
            <v-row justify="center" align="center">
              <img style="max-width: 90%;" src="@/assets/artist.png" alt="" />
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <mint-dialog />
  </v-main>
</template>

<script>
import MintPresale from "../components/MintPresale.vue";
import MintPublicAddSub from "../components/MintPublicAddSub.vue";
import MintDialog from "../components/MintDialog.vue";
import BaseButton from "../components/BaseButton.vue";
import ConnectButton from "../components/ConnectButton.vue";
import FeedbackDisplay from '../components/FeedbackDisplay.vue';

export default {
  components: {
    MintPresale,
    MintPublicAddSub,
    MintDialog,
    BaseButton,
    ConnectButton,
    FeedbackDisplay,
  },
  data() {
    return {
        currentRobber: 0
    };
  },
  methods : {

      goToAnchor(anchor){
          document.getElementById(anchor).scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
      },
      getRobber(){
          console.log("@/assets/robber-" + this.currentRobber + ".gif")
          return "@/assets/robber-" + this.currentRobber + ".gif";
      },
      rob(){
          this.currentRobber = (this.currentRobber + 1) % 4;
      }

  },

  computed: {
    connected() {
      return this.$store.state.user.connected;
    },

    whitelisted() {
      return this.$store.state.user.whitelisted;
    },

    maxSupply() {
      return this.$store.state.contract.maxSupply;
    },

    nbMinted() {
      return this.$store.state.contract.nbMinted;
    },
    carouselHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 200;
        case "sm":
          return 400;
        case "md":
        case "lg":
        case "xl":
          return 500;
        default:
          return "auto";
      }
    },
    robber(){
          return "/assets/robber-" + this.currentRobber + ".gif";
    }
  },
};
</script>

<style>
.v-main {
  background: #99e0ff;
  padding-top: 20vh;
}

h1 {
  font-size: 75px;
  line-height: 91px;
  text-align: center;
}

@media screen and (max-width: 540px) {
  h1 {
    font-size: 45px;
    line-height: 55px;
  }
}

.v-carousel .v-icon {
  color: black !important;
}

.carousel-container {
  background: linear-gradient(
    180deg,
    #f1b1f4 0%,
    #a8e7f9 49.48%,
    #eef3e3 100%
  ) !important;
  border: 1px solid #210912 !important;
  border-radius: 12px !important;
}
</style>