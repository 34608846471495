<template>
  <v-container>
    <v-row justify-sm="end" justify="center">
      <base-button @click="selectAll">SELECT ALL</base-button>
    </v-row>
    <v-row justify="start">
      <v-col
        v-for="id in nftsIds"
        :key="id"
        cols="6"
        sm="4"
        xl="3"
        class="text-center"
      >
        <v-hover>
          <v-img
            v-if="!nfts[id].sent"
            eager
            :src="nfts[id].image"
            @click="nftPicked(id)"
            style="cursor: pointer"
            rou
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="accent"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-overlay absolute :value="nfts[id].selected ? true : false">
              <div style="position: absolute; right: 10px; top: 10px">
                <v-icon v-if="nfts[id].selected" color="blue" x-large>
                  mdi-checkbox-marked-circle
                </v-icon>
                <v-icon v-if="!nfts[id].selected" color="white" x-large>
                  mdi-checkbox-blank-circle
                </v-icon>
              </div>
            </v-overlay>
          </v-img>
          <v-img v-else
            eager
            :src="nfts[id].image"
            rou
          >
          <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="accent"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-overlay absolute :value="nfts[id].selected ? true : true">
            </v-overlay>
          </v-img>
        </v-hover>
          <div class="mt-3">
            <span class="sequel-45">{{ nfts[id].name }}</span>
          </div>
      </v-col>
      <v-col v-if="!allLoaded">
        <v-progress-circular indeterminate color="accent"></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseButton from "./BaseButton.vue";
export default {
  components: { BaseButton },

  data() {
    return {
      showOverlay: false,
      allLoaded: false,
    };
  },

  mounted() {
    (async () => {
      await this.$store.dispatch("fetchOwnedNfts");
      this.allLoaded = true;
    })();
  },

  methods: {
    selectAll() {
      this.showOverlay = true;
      for (let gid of this.nftsIds) {
        this.$store.commit("setNftVar", {
          id: gid,
          val: true,
          var: "selected",
        });
      }
    },

    nftPicked(id) {
      if (!this.showOverlay) {
        this.showOverlay = true;
      }

      this.$store.commit("setNftVar", {
        id: id,
        val: !this.nfts[id].selected,
        var: "selected",
      });

      let noneSelected = true;

      for (let gid of this.nftsIds) {
        if (this.nfts[gid].selected) {
          noneSelected = false;
        }
      }

      if (noneSelected) {
        this.showOverlay = false;
      }
    },
  },

  computed: {
    nftsIds() {
      return Object.keys(this.nfts);
    },

    nfts() {
      return this.$store.state.user.nfts;
    },
  },
};
</script>

<style>
.v-overlay__content {
  width: 100%;
  height: 100%;
}

.v-image {
    border-radius: 10px;
    transition: transform .2s ease-in-out;

}

.v-image:hover {
    transform: scale(1.05);
}



</style>