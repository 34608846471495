<template>
  <v-container>
    <v-row class="" align="center" justify="center">
      <v-col cols="12">
        <v-row class="mt-10" align="center" justify="center">
          <sub-button />
          <number-to-mint />

          <add-button />
        </v-row>
        <v-row class="mt-10" align="center" justify="center">
          <mint-button
            titleButton="MINT MORE CANDY ROBBERS"
            variation="saleMint"
            :highlighted="true"
            class=""
          />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddButton from "./AddButton.vue";
import MintButton from "./MintButton.vue";
import NumberToMint from "./NumberToMint.vue";
import SubButton from "./SubButton.vue";
export default {
  components: {  MintButton, SubButton, AddButton, NumberToMint },
  data() {
    return {
      showPresale: false,
    };
  },
  methods: {
    onPresaleUp() {
      this.showPresale = true;
    },
  },
  computed: {
    saleStart() {
      return this.$store.state.contract.saleStart;
    },
    salePrice() {
      return this.$store.state.contract.salePrice / 1000000000000000000;
    },
  },
};
</script>

<style>
</style>