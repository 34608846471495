<template>
  <countdown :left-time="timeTarget - Date.now()" @finish="_onFinish" class="">
    <!-- 101 seconds -->
    <span slot="process" slot-scope="{ timeObj }" class=" sequel-45">
    {{ timeObj.d > 0 ? timeObj.d + " DAY,": "" }} {{ timeObj.h > 0 ?  timeObj.h + " HOURS," : ""}} {{ timeObj.m  > 0 ? timeObj.m + " MINUTES AND " : "" }} {{ timeObj.s > 0 ? timeObj.s + " SECONDS ": "" }}
    </span>
    <span slot="finish">Done!</span>
  </countdown>
</template>

<script>
export default {
  props: {
    timeTarget: Number,
    onFinish: Function,
  },
  methods:{
      _onFinish(){
          this.$emit("finish")
      }
  }
};
</script>

<style scoped>
.countdown{
    font-weight: bold;
    font-size: 1.5rem;
}
</style>