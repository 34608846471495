<template>
  <base-button  color="primary" title="CONNECT YOUR WALLET" @click="onConnect"></base-button>
</template>

<script>
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import BaseButton from './BaseButton.vue';

const providerOptions = {
  injected: {},
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: "b118e5231d8e4c8d9308616d279105fe", // required
    },
  },
};

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions: providerOptions // required
});

export default {
  components: { BaseButton },
    data(){
        return {
            provider: undefined
        }
    },
    methods: {
        async onConnect(){
            this.$store.dispatch("connect", web3Modal);
        }
    }
};
</script>

<style>
</style>