<template>
  <v-dialog :value="value" @input="$emit('input', false)">
    <v-sheet rounded="xl" color="white">
      <v-container>
        <v-container v-if="!success">
          <v-row justify="center" class="text-h3 mt-8 text-center">
            <span class="sequel-85">READY TO ROB!</span>
          </v-row>
          <v-row justify="center" class="mt-10">
            <img src="@/assets/transparent.png" alt="" style="width: 90%; max-width: 200px" />
          </v-row>
          <v-row justify="center" class="mt-10">
            <v-col cols="12" sm="10" xl="8">
              <div class="sequel-45 text-center">
                Your Candy Robbers are now ready to go out for a robbery.Once you click on “CONFIRM” you will get
                to sign a message on your wallet. <br />
                <br />
                Wait for the robbery to end (48 hours) and check your NFT on all
                platforms. If you win you will receive the prize on your wallet.<br />
                <br />
                So, are you ready?
              </div>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-10">
            <base-button @click="signSelected">CONFIRM</base-button>
          </v-row>
          <v-row justify="center" class="mt-10 mb-5">
            <v-col cols="12" sm="10" xl="8">
              <div class="sequel-85 text-center">
                Once you click “CONFIRM” you cannot reverse your action. Make
                sure you have selected all your NFTs you want to take to the
                robbery event.
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else>
          <v-row justify="center" class="text-h3 mt-8 text-center">
            <span class="sequel-85">You have been entered to the Robbery!</span>
          </v-row>
          <v-row justify="center" class="mt-10">
            <img src="@/assets/transparent.png" alt="" style="width: 90%; max-width: 200px" />
          </v-row>
          <v-row justify="center" class="mt-10 mb-5">
            <v-col cols="12" sm="10" xl="8">
              <div class="sequel-85 text-center">
                Congratulations! You have successfully sent your Robbers on a Heist
              </div>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-10 mb-5">
            <a href="https://twitter.com/intent/tweet?text=I%20have%20entered%20the%20%E2%80%9CChange%20of%20Heart%E2%80%9D%20robbery.%0A%0AEnter%20here%3A%20https%3A%2F%2Fwww.candyrobbersnft.com%2F%0A%40Candy_Robbers"
              target="_blank">
              <base-button>Tweet It</base-button>
            </a>
          </v-row>
        </v-container>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>

<script>
import BaseButton from "./BaseButton.vue";
export default {
  props: {
    value: Boolean,
  },
  components: { BaseButton },
  data() {
    return {};
  },

  methods: {
    async signSelected() {
      try {
        await this.$store.dispatch("signSelected");
      } catch (error) {
        console.error(error);
      }
    },
  },

  computed: {
    success() {
      return this.$store.state.user.registrationSuccess;
    },
  },
};
</script>

<style>

</style>