<template>
  <v-btn class="mint-button add-sub" rounded fab @click="onClick">
    <v-icon color="white">mdi-plus</v-icon>
  </v-btn>
</template>

<script>
export default {
    methods:{
        onClick(){
            this.$store.commit("increment");
        }
    }
};
</script>

<style scoped>
.mint-button{
    margin: 0px 10px 0px 10px;
}
.add-sub{
    background: linear-gradient(180deg, #F1B1F4 23.44%, #A8E7F9 61.98%, #EEF3E3 100%);
    border: 1px solid #210912;
    box-shadow: 0px 4px 0px #210912, inset 0px 8px 4px rgba(255, 255, 255, 0.25), inset 0px -5px 1px rgba(255, 255, 255, 0.25);
}
</style>