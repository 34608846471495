<template>
  <v-main style="padding-top: 15vh">
    <v-container>
      <v-row align="center" justify="center">
        <v-container>
          <v-row justify="center">
            <h1 class="sequel-85">THANK YOU</h1>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" lg="7" xl="5" class="text-center">
              <h3 class="sequel-85">FIRST ROBBERY: MID-JUNE</h3>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" lg="10" xl="6" class="text-center">
              <h2 class="sequel-85">
                WANT MORE CHANCE TO <span class="white--text">WIN ETH</span> &
                NFT AIRDROPS? MINT MORE CANDY ROBBERS NFT
              </h2>
            </v-col>
          </v-row>
          <v-row justify="center">
            <connect-button v-if="!connected" />
            <mint-thank-you-add-sub v-if="connected" />
          </v-row>
          <v-row justify="center">
              <feedback-display/>
          </v-row>
        </v-container>
      </v-row>
      <mint-dialog/>
    </v-container>

    <img
      src="./../assets/mosaique.png"
      style="
        margin-top: 50px;
        margin-bottom: 50px;
        min-width: 100vw;
        max-width: 100vw;
      "
    />
  </v-main>
</template>

<script>
import ConnectButton from "../components/ConnectButton.vue";
import FeedbackDisplay from '../components/FeedbackDisplay.vue';
import MintDialog from '../components/MintDialog.vue';
import MintThankYouAddSub from "../components/MintThankYouAddSub.vue";

export default {
  components: {
    MintThankYouAddSub,
    ConnectButton,
    FeedbackDisplay,
    MintDialog
  },
  data() {
    return {};
  },
  methods: {
    redirectToMint() {
      this.$router.push("/");
    },
  },
  mounted() {
    let twitterScript = document.createElement("script");
    twitterScript.setAttribute("src", "//static.ads-twitter.com/oct.js");
    twitterScript.setAttribute("type", "text/javascript");
    document.head.appendChild(twitterScript);
    twitterScript.onload = ()  => {window.twttr.conversion.trackPid("o8x3q", {
      tw_sale_amount: 0,
      tw_order_quantity: 0,
    });}
    let GAScript = document.createElement("script");
    GAScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=G-TS4H5EBWR6");
    document.head.appendChild(GAScript);
    window.dataLayer = window.dataLayer || [];
    function gtag(){
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-TS4H5EBWR6');

    this.$store.commit("setNumberToMint", 3);
  },
  computed: {
    connected() {
      return this.$store.state.user.connected;
    },

    whitelisted() {
      return this.$store.state.user.whitelisted;
    },

    maxSupply() {
      return this.$store.state.contract.maxSupply;
    },

    nbMinted() {
      return this.$store.state.contract.nbMinted;
    },

    userMinted() {
      return this.$store.state.contract.userMinted;
    },
  },
};
</script>

<style>
.v-main {
  background: #99e0ff;
  padding-top: 20vh;
}

h1 {
  font-size: 75px;
  line-height: 91px;
  text-align: center;
}

@media screen and (max-width: 540px) {
  h1 {
    font-size: 45px;
    line-height: 55px;
  }
}
</style>