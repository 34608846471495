<template>
  <v-main>
    <v-container fill-height class="justify-center text-h4">
      <v-row justify="center" class="text-subtitle-1 text-lg-h4 text-center my-3">
            <h2 class="sequel-85">
              ROBBERS  <br v-if="mobile" />
              STATISTICS
            </h2>
          </v-row>
      <h1 class="text-center sequel-85" style="line-height: 120%" ></h1>
      <data-widget contract="0x33857ad1031122a00a68e6bf9ac4475ba6c6f8be"
      fields='["Total Volume","Market Cap","Floor Price","Listed","Listed at Floor","24H Sales","Holders","Blue Chip Holders","Whale Holders"]'
      data-theme="dark" rows="2" ></data-widget>
    </v-container>
  </v-main>
</template>

<script>
export default {

}
</script>

<style>
.v-main {
  background: #99e0ff;
  padding-top: 20vh;
}

.team-member {
  color: #77d0f6 !important;
  text-decoration: none;
}

/* h1 {
  font-size: 75px;
  line-height: 91px;
  text-align: center;
}

@media screen and (max-width: 540px) {
  h1 {
    font-size: 45px;
    line-height: 55px;
  }
} */

.v-expansion-panel::before {
  box-shadow: none !important;
}

.carousel-container {
  background: linear-gradient(180deg,
      #f1b1f4 0%,
      #a8e7f9 49.48%,
      #eef3e3 100%) !important;
  border: 1px solid #210912 !important;
  border-radius: 12px !important;
}
</style>