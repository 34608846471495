<template>
  <v-btn  class="not-highlighted" @click="onClick" :disabled="disabled">{{title}}</v-btn>
</template>

<script>

export default {
    props: {
        title: String,
        color: String,
        disabled: Boolean
    },
    methods:{
        onClick(e){
            this.$emit("click", e);
        }
    }
};
</script>

<style scoped>
    .not-highlighted{ 
        background: linear-gradient(0deg, #EEF3E3, #EEF3E3);
        border: 1px solid #210912;
        box-shadow: 0px 4px 0px #210912, inset 0px 8px 4px rgba(255, 255, 255, 0.25), inset 0px -5px 1px rgba(255, 255, 255, 0.25);
        border-radius: 7px;
        font-family: Sequel45;
    }

</style>


