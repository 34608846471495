<template>
  <v-container>
    <v-row class="" align="center" justify="center">
      <v-col v-if="saleStart > 0 && !showPresale">
        <v-row class="text-h4 text-center" align="center" justify="center">
          <span class="sequel-85"> WHITELIST MINT OPENS IN:</span>
        </v-row>
        <v-row class="text-h4 text-center" align="center" justify="center">
          <countdown
            class="hanson"
            @finish="onPresaleUp"
            :timeTarget="saleStart"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-row class="" align="center" justify="center">
      <v-col v-if="showPresale">
        <v-row class="text-p text-center" align="center" justify="center">
          Max. 3 per wallet
        </v-row>
        <v-row class="text-p text-center" align="center" justify="center">
          {{presalePrice}} Ξ per NFT
        </v-row>
         
        <v-row class="mt-10" align="center" justify="center">
          <v-col cols="8" lg="2">
            <v-row align="center" justify="center">
              <mint-button :highlighted="true" variation="presaleMint" :number="3"/>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-10" align="center" justify="center">
          <v-col cols="8" lg="2">
            <v-row align="center" justify="center">
              <mint-button variation="presaleMint" :number="2"/>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-10" align="center" justify="center">
          <v-col cols="8" lg="2">
            <v-row align="center" justify="center">
              <mint-button variation="presaleMint" :number="1"/>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Countdown from "./Countdown.vue";
import MintButton from "./MintButton.vue";
export default {
  components: { Countdown, MintButton },
  data() {
    return {
      showPresale: false,
    };
  },
  methods: {
    onPresaleUp() {
      this.showPresale = true;
    },
  },
  computed: {
    saleStart() {
      return this.$store.state.contract.saleStart;
    },
    presalePrice() {
      return (this.$store.state.contract.presalePrice / 1000000000000000000);
    },
  },
};
</script>

<style>

</style>