<template>
  <v-main>
    <v-container class="fill-height" v-if="!start">
      <v-row justify="center" class="text-h3 text-sm-h5 text-center sequel-45">
        <span class="sequel-45">The robbery will start in: &nbsp;</span> <Countdown :timeTarget="1696147200000" @finish="onStart" />
          </v-row>
      
    </v-container>
    <v-container v-if="!finish && start">
      <v-container class="" v-if="!connected">
        <v-container class="mb-16 mt-10">
          <v-row justify="center" class="text-h3 text-sm-h2 text-center">
            <span class="sequel-85">CANDY HEIST</span>
          </v-row>
          <v-row justify="center" class="text-h6 text-sm-h5 text-center">
            <span class="sequel-45">CONNECT YOUR WALLET TO SEE YOUR ROBBERS!
            </span>
          </v-row>
          <v-row justify="center" class="my-16">
            <connect-button />
          </v-row>
          <v-row justify="center">
            <img src="@/assets/candy-shop.png" style="max-width: 90%" alt="" />
          </v-row>
        </v-container>
      </v-container>
      <v-container class="fill-height" v-if="connected">
        <v-container class="mb-16">
          <v-row justify="center" class="text-h3 text-sm-h2 my-10">
            <span class="sequel-85">CANDY HEIST</span>
          </v-row>
          <v-row justify="center" class="text-h6 text-sm-h5 text-center">
            <span class="sequel-45">YOU HAVE
              <Countdown :timeTarget="1696320000000" @finish="onFinish" /> LEFT! SELECT YOUR NFT TO PARTICIPATE TO THE
              ROBBERY.
            </span>
          </v-row>
        </v-container>
        <v-row justify="center">
          <connect-button v-if="!connected" />
        </v-row>
        <v-container class="mt-0 mt-sm-16">
          <v-row justify="center" align="end">
            <v-col cols="12" sm="6">
              <div class="text-h6">
                <v-row justify="center">
                  <span class="sequel-45 text-decoration-underline">You have {{ nftsIds.length }} robbers</span>
                </v-row>
              </div>
            </v-col><v-spacer></v-spacer>
            <v-col cols="12" sm="6">
              <v-row justify="center">
                <base-button class="text-subtitle-2" @click="openConfirmation">
                  <span class="sequel-45">PARTICIPATE WITH SELECTION</span>
                </base-button>
              </v-row>
            </v-col>
          </v-row>
          <v-container style="
            background-color: white;
            border-radius: 12px;
            border: 1px solid #210912;
          " class="mt-10">
            <nft-picker />
          </v-container>
        </v-container>

      </v-container>
    </v-container>
    <v-container v-if="finish && start" class="mb-16 mt-10">
      <v-row justify="center" class="text-h3 text-sm-h2 text-center">
        <span class="sequel-85">ROBBERY ENDED</span>
      </v-row>
      <v-row justify="center">
        <img src="@/assets/candy-shop.png" style="max-width: 90%" alt="" />
      </v-row>
    </v-container>

    <robbery-confirmation v-model="open" />
    <v-dialog v-model="showError" max-width="400px">
      <v-sheet rounded="lg">
        <v-container class="text-center py-10">
          <v-row justify="center" class="text-h3">
            <span class="sequel-85 red--text">ERROR</span>
          </v-row>
          <v-row justify="center" class="mt-10">
            <span class="sequel-85">PLEASE SELECT AT LEAST 1 ROBBER!</span>
          </v-row>
        </v-container>
      </v-sheet>
    </v-dialog>

  </v-main>
</template>

<script>
import BaseButton from "../components/BaseButton.vue";
import ConnectButton from "../components/ConnectButton.vue";
import Countdown from "../components/Countdown.vue";
import NftPicker from "../components/NftPicker.vue";
import RobberyConfirmation from "../components/RobberyConfirmation.vue";
export default {
  components: { BaseButton, ConnectButton, NftPicker, RobberyConfirmation, Countdown },

  data() {
    return {
      open: false,
      showError: false,
      finish: false,
      start: false,
    }
  },

  methods: {
    onStart() {
      this.start = true;
    },
    onFinish() {
      this.finish = true;
    },
    openConfirmation() {
      let noneSelected = true;

      for (let gid of this.nftsIds) {
        if (this.nfts[gid].selected) {
          noneSelected = false;
        }
      }

      if (noneSelected) {
        this.showError = true;
        return
      }
      this.open = true;
    }

  },

  computed: {
    connected() {
      return this.$store.state.user.connected;
    },
    nftsIds() {
      return Object.keys(this.nfts);
    },

    nfts() {
      return this.$store.state.user.nfts;
    },
  },
};
</script>

<style>

</style>
