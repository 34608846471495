<template>
  <div v-if="highlighted">
    <base-button color="primary" :title="title" @click="onMint" :disabled="minting"></base-button>
  </div>
  <div v-else>
    <shallow-button color="primary" :title="title" @click="onMint" :disabled="minting"></shallow-button>
  </div>
</template>

<script>
import BaseButton from './BaseButton.vue';
import ShallowButton from './ShallowButton.vue';

export default {

  components: { BaseButton, ShallowButton },
  props: {
    variation: String,
    number: Number,
    highlighted: Boolean,
    titleButton: String
  },
  data() {
    return {
      minting: false
    }
  },
  methods: {
    async onMint() {
      this.minting = true;
      if (this.number) {

        this.$store.commit("setNumberToMint", this.number);

      } else {

        this.$store.commit("setNumberToMint", this.$store.state.numberToMint);

      }

      await this.$store.dispatch(this.variation);
      this.minting = false;
    },
  },
  computed: {
    title() {
      return this.titleButton ? this.titleButton : "MINT " + this.number + " CANDY ROBBERS";
    }
  }
};
</script>

<style>
</style>