<template>
  <div  class="numberBox">
    {{title}}
  </div>
</template>

<script>

export default {
  
  props: {
    number: Number,
  },
  computed:{
    title(){
      return this.$store.state.numberToMint;
    }
  }
};
</script>

<style>
  .numberBox {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #210912;
    box-shadow: 0px 4px 0px #210912, inset 0px 8px 4px rgba(255, 255, 255, 0.25), inset 0px -5px 1px rgba(255, 255, 255, 0.25);
    border-radius: 7px;
    width: 150px;
    height: 74px;
    font-family: Sequel85;
    font-size: 52px;
  }
</style>